import React, {useEffect} from 'react'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import './Contact.css'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Contact = () => {

  // browser tab title
  useEffect(() => {
    document.title = 'Contact - TACTICA Ministries';
  }, []);

  // Email functionality
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "a517fe6f-3adc-4cd6-9b6c-04d38f98b600"); //andres: efae9b3c-bf15-4bd7-91ed-ff9435feb4d9 //tactica a517fe6f-3adc-4cd6-9b6c-04d38f98b600

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
      toast.success("Email successfully sent.")
      event.target.reset();
    }
    else {
      console.log("Error", res)
      toast.error("Error while sending email.")
    }
  };

  // Icon library
  library.add(faPhone, faEnvelope)

  return (
  <div>
    <div className='header-section-cont'>
      <div className='container'>
        <Navbar/>
        <div className='contact-head'>
          <h1>Contact Us</h1>
        </div>
      </div>
    </div>
    
    <div className='contact-section'>
      <div className='container'>
        <div className='contact-dub-info'>
          <div className='contact-det'>
            <h1>TACTICA Ministries</h1>
            <h2>5611 Springridge St. Portage, MI, USA 49024</h2>
            <p className='phone-num'><FontAwesomeIcon icon={faPhone}/>&nbsp; +1 (269) 993 - 4813</p>
            <br />
            <p className='email'><FontAwesomeIcon icon={faEnvelope}/>&nbsp; tactica3@yahoo.com</p>
          </div>

          <div className='contact-fm'>
            <form action="" onSubmit={onSubmit}>
              <input type="text" name='Name' placeholder='Name' required/>
              <input type="email" name='Email' placeholder='Email' required />
              <input type="text" name='Subject' placeholder='Subject' required/>
              <textarea name="Message" id="" rows="6" placeholder='Message'></textarea>
              <button type='submit'>Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
  )
}
export default Contact